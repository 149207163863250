<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="p-5">
    <h1>Шаблоны документов</h1>
    <table class="table">
      <tr>
        <th>ID</th>
        <th>Название</th>
        <th>Сущность</th>
      </tr>
      <tr v-for="template in templates" :key="template.id">
        <td><router-link :to="{ name: 'doc-templates/detail', params: { id: template.id } }">{{ template.id }}</router-link></td>
        <td><router-link :to="{ name: 'doc-templates/detail', params: { id: template.id } }">{{ template.title }}</router-link></td>
        <td>{{ template.entity }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DocTemplates',
  data () {
    return {
      isLoading: true,
      templates: []
    }
  },
  watch: {
    $route () {
      this.checkDetail()
    }
  },
  async created () {
    this.checkDetail()

    const templates = await this.$api.query('doc-template')
    this.templates = Array.isArray(templates.data) ? templates.data : []

    this.isLoading = false
  },
  methods: {
    checkDetail () {
      if (this.$route.params.id) {
        this.$store.dispatch('openPanel', { path: this.$route.path })
      }
    }
  }
}
</script>
