<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <h2>{{ template.title }}</h2>
    <p>{{ template.entity }}</p>
    <hr>
    <b-tabs pills card>
      <b-tab title="Предпросмотр" active>
        <div class="doc-wrapper"><div v-html="template.template" class="doc-template"></div></div>
      </b-tab>
      <b-tab title="Код">
        <b-form-textarea v-model="template.template" :rows="30"></b-form-textarea>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import '@/assets/css/doc_template.css'

export default {
  name: 'DocTemplatesDetail',
  props: ['id'],
  data () {
    return {
      isLoading: true,
      error: '',
      template: []
    }
  },
  computed: {
    currentId () {
      return this.id || this.$route.params.id
    }
  },
  watch: {
    $route () {
      this.$vuedals.close()
    }
  },
  async created () {
    try {
      this.template = await this.$api.query('doc-template/' + this.currentId)
    } catch (err) {
      this.error = err
    }

    this.isLoading = false
  }
}
</script>
